// assets
import { IconTable } from "@tabler/icons-react";

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const TableMenuReserveMenu = {
  id: "table-reservations",
  title: "Table Reservations",
  type: "item",
  url: "/portal/admin/table-reservations",
  icon: IconTable,
};

export default TableMenuReserveMenu;
