import { createContext, useContext, useEffect, useState } from "react";

const BranchContext = createContext();

export const useBranch = () => {
  return useContext(BranchContext);
};

export const BranchProvider = ({ children }) => {
  const [storedBranch, setStoredBranch] = useState(
    localStorage.getItem("branch_id") ?? null
  );

  const updateBranch = (branch_id) => {
    setStoredBranch(branch_id);
    if (branch_id) {
      localStorage.setItem("branch_id", branch_id);
    }
  };
  // useEffect to sync localStorage changes with state
  useEffect(() => {
    const handleStorageChange = () => {
      const storedBranchId = localStorage.getItem("branch_id");
      if (storedBranchId !== storedBranch) {
        setStoredBranch(storedBranchId);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [storedBranch]);

  // Value object to provide to the context
  const value = {
    storedBranch,
    updateBranch,
  };
  return (
    <BranchContext.Provider value={value}>{children}</BranchContext.Provider>
  );
};
