import NavigationScroll from "./layout/NavigationScroll";
import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import AllRoutes from "./routes/AllRoutes";
import Theme from "./themes/Theme";
import { AuthProvider } from "./context/AuthContext";
import { BranchProvider } from "./context/BranchContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  const customization = useSelector((state) => state.customization);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={Theme(customization)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AuthProvider>
            <BranchProvider>
              <CssBaseline />
              <NavigationScroll>
                <ToastContainer />
                <AllRoutes />
              </NavigationScroll>
            </BranchProvider>
          </AuthProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
