// assets
import { IconDashboard } from "@tabler/icons-react";

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const BranchUserMenu = {
  id: "panel",
  title: "Dashboard",
  type: "item",
  url: "/portal/branch-user/dashboard",
  icon: IconDashboard,
};

export default BranchUserMenu;
