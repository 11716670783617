import PortalLayout from "src/layout/PortalLayout";
import Loadable from "src/components/Loadable";
import { lazy } from "react";
// import { useAuthRole } from "src/hooks/useAuthRole";

const BranchUserDashboard = Loadable(
  lazy(() =>
    import("src/Container/BRANCH-PORTAL/Dashboard/BranchUserDashboard")
  )
);
const OrdersList = Loadable(
  lazy(() => import("src/Container/BRANCH-PORTAL/orders/BranchOrdersList"))
);
// category crud
const CategoryList = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/Categories/CategoryList"))
);
// product crud
const ProductList = Loadable(
  lazy(() => import("src/Container/ADMIN-PORTAL/products/ProductList"))
);

const BranchUserRoutes = {
  path: "portal/branch-user",
  element: <PortalLayout />,
  children: [
    {
      path: "dashboard",
      element: <BranchUserDashboard />,
    },
    {
      path: "orders",
      children: [{ path: "", element: <OrdersList /> }],
    },
    {
      path: "categories",
      children: [{ path: "", element: <CategoryList /> }],
    },
    {
      path: "products",
      children: [
        { path: ":CategoryID/:CategoryName", element: <ProductList /> },
      ],
    },
  ],
};

export default BranchUserRoutes;
