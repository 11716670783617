const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs,
  //also Don't put only '/' use blank('') instead,
  // like '/starvizz-react/default'
  basename: "",
  defaultPath: "/",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  apiBaseURL: `https://backend.themarmarket.com/api`,
  apiURL: `https://backend.themarmarket.com`,
  // apiBaseURL: `http://127.0.0.1:8000/api`,
  // apiURL: `http://127.0.0.1:8000`,
};
export default config;
