// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup/NavGroup";

import adminDashboard from "src/menu-items/AdminDashboard";
import categoriesMenu from "src/menu-items/categories";
import BranchUserCategoryMenu from "src/menu-items/BranchUserCategoryMenu";
import setup_menu from "src/menu-items/setup_menu";
import customers_menu from "src/menu-items/customers_menu";
import OrdersMenu from "src/menu-items/OrdersMenu";
import BranchUserOrdersMenu from "src/menu-items/BranchUserOrdersMenu";
import BranchUserMenu from "src/menu-items/BranchUserMenu";
import TableMenuReserveMenu from "src/menu-items/TableMenuReserveMenu";
// import shopMenu from "src/menu-items/shopMenu";
import { useEffect, useState } from "react";
import axiosInstance from "src/utils/CustomAxios";
import {
  IconShoppingCart,
  IconLeaf,
  IconBread,
  IconBox,
  IconKey,
} from "@tabler/icons-react";
import NavItem from "./NavItem/NavItem";
import config from "src/config";
import NavCollapse from "./NavCollapse/NavCollapse";
// constant
const icons = {
  IconKey,
};
// ==============================|| SIDEBAR MENU LIST ||============================== //
// Function to fetch categories and products
const fetchCategoriesAndProducts = async () => {
  try {
    const response = await axiosInstance.get("/sub-categories-menu"); // Adjust the endpoint accordingly
    return response.data;
  } catch (error) {
    console.error("Error fetching categories and products", error);
    return [];
  }
};
const MenuList = ({ AuthRole }) => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    let newMenuItems = [];
    const fetchData = async () => {
      const data = await fetchCategoriesAndProducts();
      const {
        normal_categories,
        less_calories,
        extra_categories,
        box_categories,
      } = await data;

      // Construct the "Products" parent item
      const productMenu = {
        id: "products",
        title: "Products",
        type: "group",
        icon: IconShoppingCart,
        children: [
          {
            id: "Normal",
            title: "Normal",
            type: "collapse",
            icon: IconShoppingCart,
            children: normal_categories.map((category) => ({
              id: `category-${category.id}`,
              title: category.category_name,
              url: `/${
                AuthRole == 0
                  ? "portal/admin/products"
                  : "portal/branch-user/products"
              }/${category.id}/${category.category_name
                .replace(/\s+/g, "-") // Replace spaces with hyphens
                .replace(/\//g, "-") // Replace slashes with hyphens
                .toLowerCase()}`,
              icon: icons.IconKey,
              icon_is_img: `${config.apiURL}/${category.category_image}`,
              type: "item", // Use 'item' for direct navigation
            })),
          },
          {
            id: "Less",
            title: "Less Calories",
            type: "collapse",
            icon: IconLeaf,
            children: less_calories.map((category) => ({
              id: `category-${category.id}`,
              title: category.category_name,
              url: `/${
                AuthRole == 0
                  ? "portal/admin/products"
                  : "portal/branch-user/products"
              }/${category.id}/${category.category_name
                .replace(/\s+/g, "-") // Replace spaces with hyphens
                .replace(/\//g, "-") // Replace slashes with hyphens
                .toLowerCase()}`,
              icon: icons.IconKey,
              icon_is_img: `${config.apiURL}/${category.category_image}`,
              type: "item", // Use 'item' for direct navigation
            })),
          },
          {
            id: "Extras",
            title: "Extras",
            type: "collapse",
            icon: IconBread,
            children: extra_categories.map((category) => ({
              id: `category-${category.id}`,
              title: category.category_name,
              url: `/${
                AuthRole == 0
                  ? "portal/admin/products"
                  : "portal/branch-user/products"
              }/${category.id}/${category.category_name
                .replace(/\s+/g, "-") // Replace spaces with hyphens
                .replace(/\//g, "-") // Replace slashes with hyphens
                .toLowerCase()}`,
              icon: icons.IconKey,
              icon_is_img: `${config.apiURL}/${category.category_image}`,
              type: "item", // Use 'item' for direct navigation
            })),
          },
          {
            id: "Parcel Box",
            title: "Parcel Box",
            type: "collapse",
            icon: IconBox,
            children: box_categories.map((category) => ({
              id: `category-${category.id}`,
              title: category.category_name,
              url: `/portal/admin/products/${
                category.id
              }/${category.category_name
                .replace(/\s+/g, "-") // Replace spaces with hyphens
                .replace(/\//g, "-") // Replace slashes with hyphens
                .toLowerCase()}`,
              icon: icons.IconKey,
              icon_is_img: `${config.apiURL}/${category.category_image}`,
              type: "item", // Use 'item' for direct navigation
            })),
          },
        ],
      };

      // admin and sub admin

      if (AuthRole === 0) {
        newMenuItems = [
          adminDashboard,
          OrdersMenu,
          customers_menu,
          categoriesMenu,
          productMenu,
          TableMenuReserveMenu,
          setup_menu,
        ];
      }
      if (AuthRole === 1) {
        newMenuItems = [
          BranchUserMenu,
          BranchUserOrdersMenu,
          BranchUserCategoryMenu,
          productMenu,
        ];
      }
      setMenuItems(newMenuItems);
    };

    fetchData();
  }, [AuthRole]);
  const navItems = menuItems.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      case "collapse":
        return <NavCollapse key={item.id} menu={item} level={1} />;
      case "item":
        return <NavItem key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
