// assets
import { IconBook } from "@tabler/icons-react";

// constant
const icons = {
  IconBook,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const categoriesMenu = {
  id: "categories",
  title: "Categories",
  type: "item",
  url: "/portal/admin/categories",
  icon: icons.IconBook,
};

export default categoriesMenu;
