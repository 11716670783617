// assets
import { IconShoppingCart } from "@tabler/icons-react";

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const BranchUserOrdersMenu = {
  id: "orders",
  title: "Orders",
  type: "item",
  url: "/portal/branch-user/orders",
  icon: IconShoppingCart,
};

export default BranchUserOrdersMenu;
