import { forwardRef } from "react";
import {
  ListItemButton,
  ListItemText,
  Typography,
  ListItemIcon,
} from "@mui/material";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
const LogoutNavItem = ({ logout, level }) => {
  let listItemProps = {
    component: forwardRef((props, ref) => (
      <ListItemButton ref={ref} {...props} onClick={logout} />
    )),
  };

  const itemHandler = () => {
    // Dispatch logout action if needed
  };

  // Active state logic can be added here if required

  return (
    <ListItemButton
      {...listItemProps}
      sx={
        {
          // Add similar styling as other NavItems
          // You can adjust the styling according to your UI design
        }
      }
      onClick={itemHandler}
    >
      <ListItemIcon>
        <ExitToAppIcon fontSize="small" /> {/* Adjust icon size as needed */}
      </ListItemIcon>

      <ListItemText
        primary={
          <Typography variant="body1" color="inherit">
            Logout
          </Typography>
        }
      />
    </ListItemButton>
  );
};

export default LogoutNavItem;
