// assets
import { IconBook } from "@tabler/icons-react";

// constant
const icons = {
  IconBook,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const BranchUserCategoryMenu = {
  id: "categories",
  title: "Categories",
  type: "item",
  url: "/portal/branch-user/categories",
  icon: icons.IconBook,
};

export default BranchUserCategoryMenu;
