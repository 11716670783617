import React, { useEffect, useState } from "react";
import loader_loop_gif from "../assets/loader_loop.gif";
import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const Loader = ({ height, width }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 15000);
  }, []);
  const styles = {
    padding: "5px",
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "bold",
  };
  const somethingStyles = {
    padding: "10px",
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "2%",
  };
  const contentStyles = {
    padding: "5px",
    fontSize: "18px",
    textAlign: "center",
  };
  const handleRefreshClick = () => {
    window.location.reload();
  };
  return (
    <>
      {isLoading ? (
        <img
          src={loader_loop_gif}
          height={height}
          width={width}
          alt="loader"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
        />
      ) : (
        <Card style={{ marginTop: "5%" }}>
          <CardActionArea>
            <CardMedia
              src={loader_loop_gif}
              height={height}
              width={width}
              alt="loader"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
            />
            <Typography variant="body1" sx={somethingStyles}>
              Something Went Wrong...
            </Typography>
            <Typography variant="body2" sx={contentStyles}>
              Unfortunately, we're unable to load the content right now.
            </Typography>
            <div style={styles}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleRefreshClick}
                sx={{ backgroundColor: "#1877F2", color: "white" }}
              >
                <RefreshIcon /> Refresh
              </Button>
            </div>
          </CardActionArea>
        </Card>
      )}
    </>
  );
};

export default Loader;
