import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(
    () => localStorage.getItem("accessToken") ?? null
  );

  const [user, setUser] = useState(localStorage.getItem("user") ?? null);

  const login = (userData, access_token, branch_ids = []) => {
    setUser(userData);
    setAccessToken(access_token);
    if (branch_ids.length > 0) {
      localStorage.setItem("branch_id", branch_ids[0]);
    }
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("accessToken", access_token);
  };
  // Function to handle logout
  const logout = () => {
    setUser(null);
    setAccessToken(null);
    // Clear user data and tokens from local storage
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("branch_id");
  };
  // Value object to provide to the context
  const value = {
    user,
    accessToken,
    login,
    logout,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
