// assets
import {
  IconUsers,
  IconBuildingStore,
  IconGlobe,
  IconFilter,
  IconPackages,
  IconDashboard,
} from "@tabler/icons-react";

// constant
const icons = {
  IconUsers,
  IconBuildingStore,
  IconGlobe,
  IconFilter,
  IconPackages,
};
// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const setup_menu = {
  id: "setup_menu",
  title: "Setup",
  type: "collapse",
  icon: icons.IconPackages,
  children: [
    {
      id: "filter_groups",
      title: "Special Groups",
      type: "item",
      url: "/portal/admin/filter-groups",
      icon: icons.IconFilter,
    },
    {
      id: "users",
      title: "Users",
      type: "item",
      url: "/portal/admin/users",
      icon: icons.IconUsers,
    },
    {
      id: "cities",
      title: "Cities",
      type: "item",
      url: "/portal/admin/cities",
      icon: icons.IconBuildingStore,
    },
    {
      id: "branches",
      title: "Branches",
      type: "item",
      url: "/portal/admin/branches",
      icon: icons.IconBuildingStore,
    },
    {
      id: "countries",
      title: "Countries",
      type: "item",
      url: "/portal/admin/countries",
      icon: icons.IconGlobe,
    },
    {
      id: "delivery-charges",
      title: "Delivery Charges",
      type: "item",
      url: "/portal/admin/delivery-charges",
      icon: icons.IconGlobe,
    },
    {
      id: "settings",
      title: "Settings",
      type: "item",
      url: "/portal/admin/settings",
      icon: icons.IconGlobe,
    },

    {
      id: "HomeSectionMenu",
      title: "Home Sections",
      type: "item",
      url: "/portal/admin/HomeSection",
      icon: IconDashboard,
    },
  ],
};

export default setup_menu;
