import { lazy } from "react";
import Loadable from "src/components/Loadable";
import AuthLayout from "src/layout/AuthLayout";

const AuthLogin = Loadable(
  lazy(() => import("src/Container/HOME-SCREEN/Authentication/Login"))
);

const AuthRoutes = {
  path: "",
  element: <AuthLayout />,
  children: [
    {
      path: "",
      element: <AuthLogin />,
    },
  ],
};

export default AuthRoutes;
